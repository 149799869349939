
/**
 * @name: add
 * @author: Gzm
 * @date: 2023-05-30 15:22
 * @description：销售管理-旅游-旅游管理 新增页
 * @update: 2023-05-30 15:22
 */
import {Component, Vue} from "vue-property-decorator";
import Specification from "@/views/travelManage/components/specification.vue";
import Basic from "@/views/travelManage/components/basic.vue";
import Detail from "@/views/travelManage/components/detail.vue";
import {Message} from "element-ui";
import {tourCreateApi, tourDetailApi,tourModifyApi} from "@/apis/travelManage";
import router from "@/router";
import {nextTick} from "vue";

@Component({
  components: {Detail, Basic, Specification},
  name:'travelManageAdd'
})
export default class UserIndex extends Vue {
  // 新增数据
  tourCreateForm: any = {}
  // 基础信息是否完成
  isBasic: any = true
  activeName: string = "first"
  // 规格日历备份数据
  standbyData:any = []

  handleClick(e: any) {
    //@ts-ignore
    // this.$refs.basicDom.submitForm('form')
  }

  handleTapSkip(e: any) {
    this.activeName = e
  }

  specsAllData(e: any) {
    this.tourCreateForm.tourSkuDtoList = e
    this.standbyData = e
  }

  basicData(e: any, v: any) {
    this.isBasic = v
    if (!v) {
      Message.error('基础信息填写不完善')
    } else {
      this.tourCreateForm = {...this.tourCreateForm, ...e}
      this.tourCreateForm.tourLocationIds = this.tourCreateForm.tourLocationIds.join(',')
    }
  }

  handleAdd(e: any) {
    let isTourSkuDtoList = true
    this.tourCreateForm.tourDetails = e
    //@ts-ignore
    this.$refs.basicDom.submitForm('form')
    if (!this.isBasic){
      nextTick(() => {
        this.activeName = 'first'
      })
      return
    }
    if (this.tourCreateForm.id) {
      tourModifyApi(this.tourCreateForm).then(e => {
        if (e) {
          Message.success('修改成功！')
          this.handelBack()
        }
      }).catch((e) => {
        this.tourCreateForm.tourSkuDtoList = this.standbyData
      })
    } else {
      tourCreateApi(this.tourCreateForm).then(e => {
        if (e) {
          Message.success('新增成功！')
          this.handelBack()
        }
      })
    }

    // if (!this.tourCreateForm.tourSkuDtoList) {
    //   return Message.error('请选择规格库存')
    // }
    // let newList = this.tourCreateForm.tourSkuDtoList.map((item: any) => {
    //   let newTourSkuDtoList = item.tourSkuDtoList.filter((tourItem: any) => {
    //     if (tourItem.tourTime && tourItem.tourSkuPrice && tourItem.tourNumber) {
    //     } else if (tourItem.tourSkuPrice || tourItem.tourNumber) {
    //       isTourSkuDtoList = false
    //     }
    //     return tourItem.tourTime && tourItem.tourSkuPrice && tourItem.tourNumber
    //   })
    //   return newTourSkuDtoList
    // })
    //
    // let isSpecific = false
    // newList.forEach((item:any) => {
    //   if (item.length > 0){
    //     isSpecific = true
    //   }
    // })
    // if (!isSpecific){
    //   return Message.error('请选择规格库存')
    // }
    // if (isTourSkuDtoList) {
    //   let tourSkuDtoList: any = []
    //   newList.forEach((item: any) => {
    //     tourSkuDtoList = [...tourSkuDtoList, ...item]
    //   })
    //   this.tourCreateForm.tourSkuDtoList = tourSkuDtoList
    //
    //   if (this.tourCreateForm.id) {
    //     tourModifyApi(this.tourCreateForm).then(e => {
    //       if (e) {
    //         Message.success('修改成功！')
    //         this.handelBack()
    //       }
    //     }).catch((e) => {
    //       this.tourCreateForm.tourSkuDtoList = this.standbyData
    //     })
    //   } else {
    //     tourCreateApi(this.tourCreateForm).then(e => {
    //       if (e) {
    //         Message.success('新增成功！')
    //         this.handelBack()
    //       }
    //     })
    //   }
    // } else {
    //   Message.error('规格库存填写不完善')
    // }
  }

  handelBack() {
    this.$store.dispatch('delView', {path: '/travelManage/add',name: "travelManageAdd"});
    router.push({path: '/travelManage/index'})
  }

  getEditData() {
    tourDetailApi(this.tourCreateForm.id).then(e => {
      this.tourCreateForm = {
        ...this.tourCreateForm,
        ...e
      }
    })
  }

  created() {
    this.tourCreateForm.id = this.$route.query.id
    if (this.$route.query.id) {
      this.getEditData()
    }
  }
}
